<script>
  import {currentUser} from "../stores/auth"
</script>


{#if $currentUser}
  Welcome, {$currentUser.email}!
{:else}
  Welcome!
{/if}
